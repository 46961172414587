@import '../../../../styles/partials/colors';
@import '../../../../styles/partials/variables';
@import '../../../../styles/shared/table';

.payments {

  td:last-child {
    width: 40px; }

  td:last-child, .amount {
    text-align: end; }

  td.date {
    color: $color--tint;
    font-size: 14px;
    font-weight: bold;
    text-align: start;
    vertical-align: bottom; }

  tbody {
    tr.total {
      td {
        border-bottom: none; } }

    tr.total, tr.date {
      td {
        padding-top: $margin--sections; }


      &:hover {
        background-color: transparent; } } } }

.button {
  position: absolute;
  right: 15px;
  top: 15px; }

.buttonPrint {
  margin-top: 20px; }

.done {
  height: 15px; }

.legend {
  color: $color--tint;
  display: block;
  margin-bottom: 8px; }

.time {
  color: $color--dark-grey;
  font-weight: bold;
  font-size: 12px;
  padding-right: 16px; }
