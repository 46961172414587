@import '../../../styles/common';

.control {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin-right: 14px;
  margin-top: 6px;
  position: relative;
  user-select: none; }

.field {
  display: none; }

.label {
  background-color: $color--light-grey;
  border-radius: 3px;
  display: block;
  line-height: 1;
  padding: 8px 16px;

  transition: background $duration, transform $duration;
  user-select: none;

  &:active {
    transform: translateY(1px);
    @include faster(); }

  &:hover {
    background: darken($color--light-grey, 5%);
    @include faster(); }

  input:checked + & {
    background-color: $color--primary-green;
    color: #fff;

    &:hover {
      background-color: $color--primary-green; } } }
