@import '../../../styles/partials/common';

$size--subtitle: 24px;
$size--normal: 18px;
$size--medium: 16px;
$size--small: 14px;

.p {
  font-family: $font-family; }

.p-subtitle {
  @include for-tablet-only {
    font-size: 22px; }

  font-size: $size--subtitle;
  font-weight: normal;
  line-height: (36 / 24); }

.p-body {
  font-size: $size--normal;
  font-weight: normal;
  line-height: (29 / 18); }

.p-body-medium {
  @include for-tablet-only {
    font-size: 18px; }

  font-size: $size--medium;
  font-weight: normal;
  line-height: (24 / 16); }

.p-body-small {
  @include for-tablet-only {
    font-size: 16px; }

  font-size: $size--small;
  font-weight: normal;
  line-height: (20 / 14); }

.p-bold {
  font-size: $size--normal;
  font-weight: 600;
  line-height: (24 / 18); }

.p-bold-medium {
  font-size: $size--medium;
  font-weight: 600;
  line-height: (17 / 16); }

.p-bold-small {
  font-size: $size--small;
  font-weight: $bold;
  line-height: (17 / 14); }

.tint {
  color: $color--tint; }

.blue {
  color: $color--dark-blue;

  &.tint {
    color: $color--light-blue; } }
