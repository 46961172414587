@import '../../../styles/partials/mixins';

.spacer {
  text-align: center;

  & > * {
    margin-bottom: 16px; } }

.img {
  @include for-phone-only {
    width: 200px;
    height: 200px; }

  width: 300px;
  height: 300px;
  display: inline-block; }

