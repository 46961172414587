// fonts
$font-size: 16px;
$line-height: 1.5;
$font-basic: Arial, Helvetica, sans-serif;
$font-family: 'Graphik', $font-basic;
$regular: normal;
$bold: bold;
$black: 900;
$medium: 500;

// duration
$duration: .35s;

// values
$margin--sections: 1.5rem;

$content--max-width: 1180px;
$page--inset: 15px;

$header-height-mobile: 56px;
$header-height: 60px;

$phone-size: 480px;
$tablet-size: 768px;
$desktop-size: $tablet-size + 1px;

// often used screen sizes for responsive sites
// 320px
// 480px
// 600px
// 768px
// 900px
// 1024px
// 1200px
