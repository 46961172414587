@import "variables";

@mixin noselect {
  user-select: none; }

@mixin faster {
  transition-duration: ($duration / 2); }

@mixin full-sized {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

@mixin size--square($size: 28px) {
  height: $size;
  width: $size; }

@mixin ease-in-out-sine {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95); }

@mixin ease-out-cubic {
  transition-timing-function: cubic-bezier(.215, .61, .355, 1); }

@mixin for-desktop-only {
  @media (min-width: $desktop-size) {
    @content; } }

@mixin for-tablet-only {
  @media (max-width: $tablet-size) {
    @content; } }

@mixin not-mobile {
  @media (min-width: $phone-size + 1) {
    @content; } }

@mixin for-phone-only {
  @media (max-width: $phone-size) {
    @content; } }
