@import 'styles/common';
@import 'styles/partials/common';
@import 'assets/fonts/graphik.sass';


body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  min-width: 270px; }

#root {
  height: 100%;
  overflow: auto;
  width: 100%;

  @media print {
    height: auto; } }
