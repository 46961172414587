@keyframes spin {
  to {
    transform: rotate(360deg); } }

.spinner {
  -ms-flex-align: center;
  -ms-flex-direction: row;
  -ms-flex-pack: center;
  align-items: center;
  bottom: 0;
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  font-size: 11px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-indent: -9999em;
  top: 0;
  z-index: 700;

  &:after {
    animation: spin 1s infinite linear;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjkwIDI5MCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjkwIDI5MCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBmaWxsPSIjQkJCQkJCIiBkPSJNMTQ1LDI0MS42Yy01My4zLDAtOTYuNi00My4yLTk2LjYtOTYuNmMwLTUzLjMsNDMuMi05Ni42LDk2LjYtOTYuNmM1My4zLDAsOTYuNiw0My4yLDk2LjYsOTYuNg0KCWMwLDI2LjctMTAuOCw1MC45LTI4LjMsNjguM2w3LjYsNy42YzE5LjQtMTkuNCwzMS41LTQ2LjMsMzEuNS03NS45YzAtNTkuMy00OC0xMDcuMy0xMDcuMy0xMDcuM1MzNy43LDg1LjcsMzcuNywxNDUNCgljMCw1OS4zLDQ4LDEwNy4zLDEwNy4zLDEwNy4zVjI0MS42eiIvPg0KPC9zdmc+DQo=');
    background-repeat: no-repeat;
    background-size: 50px 50px;
    content: '';
    display: block;
    height: 50px;
    margin: 0 auto;
    width: 50px; } }

.fixed {
  position: fixed; }
