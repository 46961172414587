@import 'reset';
@import 'partials/common';


html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font: 300 $font-size $font-family;
  height: 100%; }
