@import '../../../../styles/partials/common';

.button {
  @include noselect;
  background-color: transparent;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid $color--separator;
  cursor: pointer;
  line-height: 1.5;
  margin: 0;
  min-height: 48px;
  padding: 4px 8px;
  text-align: center;
  vertical-align: middle;

  &:active,
  &:focus {
    -webkit-tap-highlight-color: transparent;
    background-color: $color--separator;
    opacity: 1; }

  &:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px; } }

.default,
.primary {
  color: $color--dark-blue; }

//.error,
.primary {
  font-weight: $bold; }

.error {
  color: $color--red; }
