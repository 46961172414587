@import '../../../styles/partials/colors';


.root {
  background-color: $color--white;
  border: 1px solid transparent;
  color: rgba(0, 0, 0, .87);
  transition: box-shadow 300ms cubic-bezier(.4, 0, .2, 1) 0ms, border-color 300ms cubic-bezier(.4, 0, .2, 1) 0ms; }

.rounded {
  border-radius: 6px; }

.outlined {
  border: 1px solid $color--light-grey; }

.elevation0 {
  box-shadow: none; }

.elevation1 {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12); }

.elevation2 {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12); }

.elevation3 {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12); }

.elevation4 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10 0 rgba(0, 0, 0, .12); }

.elevation5 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12); }

.elevation6 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12); }

.elevation7 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, .2), 0 7px 10 1px rgba(0, 0, 0, .14), 0 2px 16px 1px rgba(0, 0, 0, .12); }

.elevation8 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12); }

.elevation9 {
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, .2), 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12); }

.elevation10 {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12); }

.elevation11 {
  box-shadow: 0 6px 7px -4px rgba(0, 0, 0, .2), 0 11px 15px 1px rgba(0, 0, 0, .14), 0 4px 20 3px rgba(0, 0, 0, .12); }

.elevation12 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12); }

.elevation13 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12); }

.elevation14 {
  box-shadow: 0 7px 9px -4px rgba(0, 0, 0, .2), 0 14px 21px 2px rgba(0, 0, 0, .14), 0 5px 26px 4px rgba(0, 0, 0, .12); }

.elevation15 {
  box-shadow: 0 8px 9px -5px rgba(0, 0, 0, .2), 0 15px 22px 2px rgba(0, 0, 0, .14), 0 6px 28px 5px rgba(0, 0, 0, .12); }

.elevation16 {
  box-shadow: 0 8px 10 -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30 5px rgba(0, 0, 0, .12); }

.elevation17 {
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, .2), 0 17px 26px 2px rgba(0, 0, 0, .14), 0 6px 32px 5px rgba(0, 0, 0, .12); }

.elevation18 {
  box-shadow: 0 9px 11px -5px rgba(0, 0, 0, .2), 0 18px 28px 2px rgba(0, 0, 0, .14), 0 7px 34px 6px rgba(0, 0, 0, .12); }

.elevation19 {
  box-shadow: 0 9px 12px -6px rgba(0, 0, 0, .2), 0 19px 29px 2px rgba(0, 0, 0, .14), 0 7px 36px 6px rgba(0, 0, 0, .12); }

.elevation20 {
  box-shadow: 0 10 13px -6px rgba(0, 0, 0, .2), 0 20 31px 3px rgba(0, 0, 0, .14), 0 8px 38px 7px rgba(0, 0, 0, .12); }

.elevation21 {
  box-shadow: 0 10 13px -6px rgba(0, 0, 0, .2), 0 21px 33px 3px rgba(0, 0, 0, .14), 0 8px 40 7px rgba(0, 0, 0, .12); }

.elevation22 {
  box-shadow: 0 10 14px -6px rgba(0, 0, 0, .2), 0 22px 35px 3px rgba(0, 0, 0, .14), 0 8px 42px 7px rgba(0, 0, 0, .12); }

.elevation23 {
  box-shadow: 0 11px 14px -7px rgba(0, 0, 0, .2), 0 23px 36px 3px rgba(0, 0, 0, .14), 0 9px 44px 8px rgba(0, 0, 0, .12); }

.elevation24 {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12); }
