@import '../../../../styles/partials/mixins';

.root {
  align-items: center;
  background-color: rgb(50, 50, 50);
  color: #fff;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 6px 16px;

  @mixin for-desktop-only {
    flex-grow: initial;
    min-width: 288px; } }

.message {
  padding: 8px 0;

  span {
    align-items: center;
    display: flex; }

  .icon {
    height: 24px;
    margin-right: 8px;
    opacity: 0.9;
    width: 24px; } }

.action {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -8px;
  padding-left: 16px; }
