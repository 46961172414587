@import '../../../styles/partials/mixins';

$margin: 8px;
$margin-desktop: 24px;

.root {
  align-items: center;
  display: flex;
  justify-content: center;
  left: $margin;
  position: fixed;
  right: $margin;
  z-index: 1400; }

.anchorOriginTopCenter {
  top: $margin;

  @include for-desktop-only {
    left: 50%;
    right: auto;
    top: $margin-desktop;
    transform: translateX(-50%); } }

.anchorOriginBottomCenter {
  bottom: $margin;

  @include for-desktop-only {
    bottom: $margin-desktop;
    left: 50%;
    right: auto;
    transform: translateX(-50%); } }

.anchorOriginTopRight {
  justify-content: flex-end;
  top: $margin;

  @include for-desktop-only {
    left: auto;
    right: $margin-desktop;
    top: $margin-desktop; } }

.anchorOriginBottomRight {
  bottom: $margin;
  justify-content: flex-end;

  @include for-desktop-only {
    bottom: $margin-desktop;
    left: auto;
    right: $margin-desktop; } }

.anchorOriginTopLeft {
  justify-content: flex-start;
  top: $margin;

  @include for-desktop-only {
    left: $margin-desktop;
    right: auto;
    top: $margin-desktop; } }

.anchorOriginBottomLeft {
  bottom: $margin;
  justify-content: flex-start;

  @include for-desktop-only {
    bottom: $margin-desktop;
    left: $margin-desktop;
    right: auto; } }
