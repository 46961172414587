@import '../../../styles/partials/common';


.h {
  display: block;
  font-family: $font-family;
  line-height: 1.1;
  margin-left: -.07em;

  &:last-child {
    margin-bottom: 0; } }

.h1 {
  @include for-desktop-only {
    font-size: 64px;
    margin-bottom: 24px; }

  font-size: 42px;
  font-weight: $black;
  line-height: 1;
  margin-bottom: 16px; }

.h2 {
  @include for-desktop-only {
    font-size: 45px;
    line-height: 54px;
    margin-bottom: 16px; }

  font-size: 34px;
  font-weight: $bold;
  line-height: 38px;
  margin-bottom: 16px; }

.h3 {
  font-size: 28px;
  font-weight: $medium;
  line-height: 34px;
  margin-bottom: 8px; }

.h4 {
  font-size: 18px; }

.h4,
.h5 {
  font-weight: $medium;
  line-height: 24px;
  margin-bottom: 8px; }

.h5 {
  font-size: 16px; }

.h6 {
  font-size: 14px;
  font-weight: $bold;
  line-height: 21px;
  margin-bottom: 8px; }

.blue {
  color: $color--dark-blue; }
