@mixin whirl($spinnerSize, $spinnerWidth, $spinnerColor) {
  animation: spin 0.75s infinite linear;
  border: $spinnerWidth solid $spinnerColor;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: $spinnerSize;
  position: relative;
  vertical-align: top;
  width: $spinnerSize;

  &::before,
  &::after {
    border: inherit;
    border-radius: inherit;
    box-sizing: border-box;
    content: '';
    height: inherit;
    left: (- $spinnerWidth);
    position: absolute;
    top: (- $spinnerWidth);
    width: inherit; }

  &,
  &:before {
    border-color: $spinnerColor transparent transparent transparent;
    display: inline-block; }

  &:before {
    animation: spin 1.5s infinite ease; }

  @keyframes spin {
    from {
      transform: rotate(0deg); }
    to {
      transform: rotate(360deg); } } }
