@import '../../../../styles/partials/common';


.description {
  color: $color--light-blue;
  display: block;
  font-size: 14px;
  font-weight: $regular;
  line-height: 18px;
  margin: -3px 0 -4px; }
