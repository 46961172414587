@import '../../../styles/partials/common';
@import '../../../styles/ui/whirl';

.wirl {
  @include whirl(100px, 10px, $color--primary-green); }

.content {
  position: relative;
  text-align: center;
  margin-top: 40px;

  .done {
    width: 400px;
    height: 400px;
    position: absolute;
    top: -150px;
    left: 50%;
    transform: translate(-50%, 0); }

  .doneText {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translate(-50%, 0); }

  .loadingText {
    padding-top: 35px; } }
