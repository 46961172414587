@import '../../../styles/partials/common';


$action--padding: 8px;
$action--width: 28px;
$action--size: $action--padding + $action--width;

.input {
  align-items: center;
  border: 2px solid $color--light-grey;
  border-radius: 5px;
  cursor: text;
  display: flex;
  font-family: $font-family;
  font-size: $font-size;
  margin: 12px 0;
  max-width: 100%;
  transition: opacity $duration;

  .wrap {
    display: flex;
    flex: 1;
    position: relative;

    input, textarea {
      flex: 1; } }

  input,
  textarea {
    background-color: transparent;
    border: 0;
    border-radius: 3px;
    height: 100%;
    margin: 1px;
    padding: 15px; }

  textarea {
    margin-top: 10px;
    padding-top: 5px; }

  label {
    background-color: transparent;
    color: $color--grey;
    cursor: text;
    left: 8px;
    max-width: 100%;
    overflow: hidden;
    padding: 0 7px;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    transition: background-color $duration * .5 ease-out, transform $duration * .5 cubic-bezier(.215, .61, .355, 1), color $duration * .5 cubic-bezier(.215, .61, .355, 1), font-size $duration * .5 cubic-bezier(.215, .61, .355, 1);
    user-select: none;
    white-space: nowrap;
    z-index: 1;

    &.uppercase {
      text-transform: uppercase; } }

  .action {
    cursor: pointer;
    margin: 0 $action--padding;
    width: $action--width;

    & > * {
      opacity: 1;
      transition: fill $duration * .5 ease-out; }

    svg {
      fill: $color--grey; } } }

.focused {
  border-color: $color--dark-blue!important;

  label {
    color: $color--dark-blue; }

  .action {
    svg {
      fill: $color--dark-blue; } } }

.active {
  label {
    background-color: $color--white;
    transform: translateY((-22px)) scale(.9) translateZ(0); } }

.error {
  border-color: $color--red;

  input,
  textarea {
    color: $color--red; }

  label {
    color: $color--red; }

  .action {
    svg {
      fill: $color--red; } } }

.disabled {
  border-color: $color--pale;
  color: $color--light-grey;
  cursor: not-allowed;

  input,
  textarea {
    cursor: not-allowed; } }

.bottomBorder {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid $color--light-grey; }
