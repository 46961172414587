@import '../../../styles/shared/table';

.stores {
  tbody {
    tr {
      cursor: pointer; } } }

.buttonAdd {
  position: absolute;
  right: 15px;
  top: 15px; }
