$color--dark-blue: #454d66;
$color--light-blue: lighten($color--dark-blue, 30);
$color--red: #f76262;
$color--primary-green: #009975;
$color--primary-green--hover: darken($color--primary-green, 10%);
$color--primary-green--shadow: #9ccabf;
$color--green-yellow: #58b368;
$color--yellow: #d9d872;
$color--yellow-light: #fff1c1;
$color--pale: #f8f8f8;
$color--light-grey: #d8d8d8;
$color--dark-grey: #262626;
$color--grey: #949494;
$color--tint: #929292;
$color--separator: #efefef;
$color--white: #fff;
