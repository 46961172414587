@import '../../../styles/partials/variables';

.title {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: $margin--sections; }

.header {
  display: inline-block;
  flex-grow: 1; }

.backButton {
  width: 32px;
  height: 32px;
  margin-right: 14px;
  cursor: pointer;

  @media print {
    display: none; } }
