@import '../../../../styles/partials/common';


.title {
  color: $color--dark-blue;
  display: block;
  font-size: 18px;
  font-weight: $bold;
  line-height: 24px;
  margin: -4px 0 -6px; }
