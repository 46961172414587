@import '../../../../styles/partials/mixins';
@import '../../../../styles/partials/animations';


.backdrop {
  -ms-flex-align: center;
  -ms-flex-direction: column;
  -ms-flex-pack: distribute;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -webkit-justify-content: space-around;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.dialog,
.container,
.message,
.actions {
  display: flex;
  flex-direction: column;
  flex-shrink: 0; }

.dialog {
  -webkit-animation: PBCoreModalShow .1s ease-out;
  -webkit-flex-shrink: 1;
  animation: PBCoreModalShow .1s ease-out;
  background-color: #fff;
  border-radius: 12px;
  flex-shrink: 1;
  margin: 20px;
  max-height: calc(100% - 40px);
  width: 260px;

  @include for-desktop-only {
    width: 400px; } }

.container {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center; }

.message {
  margin: 16px 32px;
  text-align: center; }

.message:first-child {
  margin-top: 32px; }

.message > *:nth-child(n+2) {
  padding-top: 16px; }

.actions {
  margin-top: 16px;

  &:only-child {
    margin-top: 0;

    .button:first-child {
      border-top: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px; } } }

.fields {
  padding: 10px; }
