@import '../../../styles/partials/common';

.payment {
  padding: 15px;
  border-bottom: 1px solid $color--separator;
  position: relative;

  @include for-phone-only {
    padding: 5px; }

  .code {
    display: inline-block;
    font-size: 34px;
    font-weight: 400;
    color: $color--dark-blue;
    padding-right: 20px;
    padding-left: 20px;
    font-family: monospace;

    @include for-tablet-only {
      padding: 5px 10px 5px 0; }

    @include for-phone-only {
      font-size: 30px;
      padding: 5px 5px 5px 0; } }

  .details {
    display: inline-block;
    @include for-phone-only {
      font-size: 14px; } }

  .done {
    position: absolute;
    top: -15px;
    right: 0;
    width: 100px;
    height: 100px;

    @include for-phone-only {
      top: -25px;
      right: -25px; } } }
