@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-ms-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }

// LIST SHOW
@include keyframes(PBCoreSlideInUp) {
  0% {
    -webkit-transform: translateY(40px);
    opacity: 0 {
      transform: translateY(40px); } }
  to {
    opacity: 1;
    transform: translateY(0); } }


@include keyframes(PBCoreSlideInDown) {
  0% {
    -webkit-transform: translateY(-40px);
    opacity: 0 {
      transform: translateY(-40px); } }
  to {
    -webkit-transform: translateY(0);
    opacity: 1;
    transform: translateY(0); } }

// MODAL SHOW
@include keyframes(PBCoreModalShow) {
  0% {
    -webkit-transform: scale(1.2);
    opacity: 0 {
      transform: scale(1.2); } }
  to {
    -webkit-transform: scale(1);
    opacity: 1;
    transform: scale(1); } }

@include keyframes(PBCoreExpandX) {
  0% {
    -webkit-transform: scaleX(.3);
    opacity: 0 {
      transform: scaleX(.3); } }
  to {
    -webkit-transform: scaleX(1);
    opacity: 1;
    transform: scaleX(1); } }

@include keyframes(PBCoreFadeIn) {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
