@import '../../../styles/partials/colors';
@import '../../../styles/partials/mixins';


.tabBar {
  @include for-phone-only {
    background-color: $color--white;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 2; }

  border-top: 1px solid $color--separator;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 1px;
  position: relative;
  text-align: center; }
