html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th,
td {
  text-align: left; }

textarea {
  resize: none; }

address {
  font-style: normal; }

input, textarea, button, select {
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  font: inherit;
  outline: none; }

select:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset; }
