@import '../../../styles/partials/colors';
@import '../../../styles/partials/mixins';

.logo {
  @include noselect;
  display: inline-block;
  font-size: 0;
  position: relative;
  text-decoration: none;
  vertical-align: top; }

.postfix {
  color: $color--dark-blue;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding-left: 6px;
  padding-top: 6px;
  text-transform: uppercase;
  vertical-align: top; }
