@import '../../styles/partials/common';

body {
  -webkit-overflow-scrolling: touch; }

.wrap {
  align-items: center;
  background-color: $color--pale;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100vh;
  padding: 16px;
  width: 100%;

  .inner {
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 100px 60px 30px;
    position: relative;
    width: 960px;

    .logo {
      left: 50%;
      position: absolute;
      top: -60px;
      transform: translate(-50%, 0); }

    .img {
      display: inline-block;
      vertical-align: top;
      width: 420px;

      img {
        max-width: 100%; } }

    .fields {
      display: inline-block;
      vertical-align: top;
      width: 400px;

      .wide {
        margin-bottom: 18px; }

      .field {
        margin-top: 10px; }

      .to_right {
        float: right; } } } }

// responsive
@media screen and (max-width: 1000px) {
  .wrap {
    .inner {
      padding: 60px 90px 60px 30px;

      .fields {
        width: 50%; }

      .img {
        width: 50%; } } } }

@media screen and (max-width: 810px) {
  .wrap {
    background-color: #fff;
    padding: 0;

    .inner {
      box-shadow: none;
      padding: 60px 90px 60px 90px;

      .logo {
        display: none; }

      .fields {
        width: 100%;

        .wide {
          margin-bottom: 30px; } }

      .img {
        display: none; } } } }

@media screen and (max-width: 576px) {
  .wrap {
    .inner {
      padding: 60px 30px 60px 30px; } } }

@media screen and (max-width: 320px) {
  .wrap {
    .inner {
      padding: 60px 15px 60px 15px; } } }
