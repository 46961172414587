@mixin font($font-family, $url, $weight) {
  @font-face {
    font-family: '#{$font-family}';
    font-weight: $weight;
    src: url('#{$url}.eot');
    src: url('#{$url}.eot?#iefix') format('embedded-opentype'), url('#{$url}.woff2') format('woff2'), url('#{$url}.woff') format('woff'), url('#{$url}.ttf') format('truetype'); } }

@include font('Graphik', 'Graphik-Regular-Web', 400);
@include font('Graphik', 'Graphik-Medium-Web', 500);
@include font('Graphik', 'Graphik-Bold-Web', 700);
@include font('Graphik', 'Graphik-Black-Web', 900);
