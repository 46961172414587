@import '../../../../styles/partials/colors';
@import '../../../../styles/partials/mixins';


.item {
  @include for-tablet-only {
    flex: 1 1 auto; }

  @include for-phone-only {
    height: 44px; }

  color: $color--tint;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 52px;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;

  &:not(:last-child) {
    @include for-desktop-only {
      margin-right: 36px; } } }

.content {
  align-items: center;
  display: flex; }

.text {
  @include for-tablet-only {
    display: none; }

  margin-left: 6px; }

.icon {
  @include for-tablet-only {
    @include size--square(24px); }

  @include size--square(12px);
  fill: $color--tint; }

.active {
  @include for-desktop-only {
    border-top: 1px solid $color--dark-grey;
    color: $color--dark-grey;
    margin-top: -1px; }

  .icon {
    @include for-tablet-only {
      fill: $color--dark-blue; }

    fill: $color--dark-grey; } }

.faded {
  opacity: .54; }
