@import '../../../styles/partials/common';
@import '../../../styles/ui/whirl';

.oneSymbol {
  min-width: 0 !important;

  .inner {
    font-size: 25px;
    padding: 5px 35px !important;

    @include for-phone-only {
      padding: 5px 10px !important; } } }

.btn {
  @include noselect;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: $color--white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  min-width: 200px;
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  transition: color $duration;
  vertical-align: middle;
  white-space: nowrap;

  @media print {
    display: none; }

  &:not([disabled]):active,
  &:not(.disabled):active {
    .inner {
      @include faster;
      transform: translateY(2px); } }

  &::before {
    @include full-sized;
    background-color: transparent;
    border-radius: 4px;
    box-shadow: 0 2px 4px $color--primary-green--shadow;
    content: '';
    transform: translateZ(0);
    transform-origin: 50% 100%;
    transition: transform $duration, opacity $duration; }

  .inner {
    background-color: $color--primary-green;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    padding: 18px 22px;
    position: relative;
    transition: background-color $duration, transform $duration, box-shadow $duration;
    z-index: 1;

    .whirl {
      @include whirl(15px, 2px, currentColor); } }

  &:hover,
  &.hover {
    background-color: transparent;
    color: $color--white;

    .inner {
      @include faster;
      background-color: $color--primary-green--hover; } } }

.active {
  .inner {
    @include faster;
    transform: translateY(2px); } }

.uppercase {
  letter-spacing: .4px;
  text-transform: uppercase; }

.arrow {
  @include ease-in-out-sine;
  fill: currentColor;
  margin-top: -7px;
  position: absolute;
  right: 22px;
  top: 50%;
  transition: transform $duration;
  width: 34px; }

.arrowed {
  text-align: left;

  .inner {
    padding-right: 80px;

    .whirl {
      @include whirl(15px, 2px, currentColor);
      margin-top: -7px;
      position: absolute;
      right: 22px;
      top: 50%; }

    &:hover {
      .arrow {
        @include faster;
        transform: translateX(3px) translateZ(0); } } } }

.bordered {
  background-color: transparent;
  color: $color--primary-green;

  &::before {
    display: none; }

  .inner {
    background-color: transparent;
    border: 2px solid $color--primary-green;

    &:hover {
      background-color: $color--primary-green;
      border-color: $color--primary-green; } } }

.small {
  font-size: 12px;

  .inner {
    padding: 15px 30px 14px; } }

.disabled {
  cursor: not-allowed;

  &::before {
    box-shadow: 0 2px 4px $color--light-grey; }

  .inner {
    background-color: $color--grey; }

  &:hover, &.hover {
    .inner {
      background-color: $color--grey; } }

  &.bordered {

    color: $color--grey;

    .inner {
      background-color: transparent;
      border-color: $color--grey;

      &:hover {
        background-color: transparent;
        border-color: $color--grey; } } } }
