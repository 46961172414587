@import '../../../styles/partials/common';

.toolbar {
  @include for-desktop-only {
    height: $header-height; }

  border-bottom: 1px solid $color--separator;
  height: $header-height-mobile;
  left: 0;
  display: table;
  top: 0;
  width: 100%;
  z-index: 90;

  .content {
    display: table-cell;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;

    .logo {
      display: inline-block; }

    .icon {
      float: right;
      display: inline-block;
      width: 25px;
      cursor: pointer; } }

  @media print {
    display: none; } }
