@import '../../styles/partials/colors';
@import '../../styles/partials/variables';
@import '../../styles/shared/table';

.keys, .actions {
  margin-bottom: $margin--sections; }

.keys {
  td, th {
    &:last-child {
      padding-right: 0;
      text-align: end; } } }

.legend {
  color: $color--tint;
  display: block;
  margin-bottom: 8px; }

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 1;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 8px;

    &:last-child {
      margin-left: 8px; } } }



