@import '../partials/colors';
@import '../partials/variables';


table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: $margin--sections;
  width: 100%;

  th {
    font-weight: bold; }

  td, th {
    @media (max-width: 450px) {
      &.hideable {
        display: none; } }

    &:last-child {
      padding-right: 0; }

    &:first-child {
      padding-left: 0; }

    border-bottom: 1px solid $color--separator;
    padding: 12px 15px;
    text-align: left; }

  tbody {
    tr:hover {
      background-color: $color--pale;
      transition: background-color $duration / 2 cubic-bezier(0.4, 0, 1, 1), opacity $duration cubic-bezier(0.4, 0, 1, 1); } } }
